//Arabic specific Ratings and Reviews Styles

/*
 * Regional specific width declartion to render exact design as same as Mac AE English site.
 * Reference width from BVRR styles
 */

$bp--xxsmall-down: max-width 400px;
$bp--msmall--down: max-width 568px;
$bp--smedium--down: max-width 744px;
$bp--xmedium--down: max-width 845px;
$bp--slarge--down: max-width 970px;

/*
 * breakpoint from base file
 *
 * $bp--xsmall-down : 480 px;
 * $bp--small-down : 640px;
 * $bp--medium-down : 768px;
 *
 */

// Over all Rating Star

.BVRROverallRatingContainer {
  margin: 0;
}

.BVRRRatingEntry {
  clear: both;
}

.BVRRRatingNormal {
  clear: both;
}

.BVRRRootElement {
  input,
  select,
  textarea {
    font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
    color: #000000;
    font-size: 1rem;
    line-height: 1.5;
  }
  a:visited {
    color: #000000;
    text-decoration: none;
  }
  a:hover {
    color: #68696d;
    text-decoration: none;
  }
  a:focus {
    color: #68696d;
    text-decoration: none;
  }
  a {
    color: #000000;
    border-style: solid;
    border-color: #dbdbdb;
    border-width: 0 0 1px;
    text-decoration: none;
  }
  img {
    border-width: 0;
  }
  .BVDI_FVReportLink {
    a:hover,
    a:focus,
    a:visited {
      color: #a3a3a3;
      font-size: 1rem;
      line-height: 1.5;
    }
  }
  input[type='text'],
  textarea {
    cursor: text;
  }
  input[type='radio'],
  input[type='checkbox'],
  input[type='submit'] {
    cursor: pointer;
  }
}

.BVRRFieldText {
  cursor: text;
}

.BVRRFieldInput {
  cursor: pointer;
}

// Product Reviews style

.BVRRContainer {
  max-width: 2030px;
  margin: 0 auto;
  .BVDI_QTHeader {
    border-style: solid;
    border-color: #c7c7c7;
    border-width: 0 0 1px;
    padding: 0 0 1em;
    .BVDI_QTTitle {
      text-align: center;
      display: block;
      margin: 1em 0;
    }
    .BVRRDisplayContentSubtitle {
      text-align: #{$rdirection};
      display: block;
    }
  }
  .BVDI_QTAttributes {
    border-#{$rdirection}: 1px solid #c7c7c7;
    float: #{$ldirection};
    height: 60px;
    width: 41%;
    @include breakpoint($bp--small-down) {
      display: none;
    }
    @include breakpoint($bp--medium-down) {
      width: auto;
    }
  }
  .BVDI_QT {
    .BVDI_QTFilterSummary {
      @include breakpoint($bp--small-down) {
        display: none;
      }
    }
  }
  .BVDI_QTHeaderAttributes,
  .BVDI_QTBodyAttributes {
    display: block;
    float: #{$ldirection};
  }
  .BVDI_QTSummaryBox {
    border-#{$rdirection}: 1px solid #c7c7c7;
    float: #{$ldirection};
    padding-#{$ldirection}: 15px;
    height: 60px;
    width: auto;
    .BVRRRatingSummary {
      .BVRROverallRatingContainer {
        .BVRRRatingNormalImage {
          float: #{$ldirection};
          line-height: 3;
          padding-#{$rdirection}: 0;
          @include breakpoint($bp--smedium--down) {
            line-height: 1.25;
          }
        }
      }
    }
    @include breakpoint($bp--xmedium--down) {
      width: auto;
      @include swap_direction(padding, 0 0 0 5px);
    }
    @include breakpoint($bp--small-down) {
      width: 25%;
      padding-#{$ldirection}: 15px;
    }
    @include breakpoint($bp--msmall--down) {
      width: 27%;
    }
    @include breakpoint($bp--xsmall-down) {
      width: 50%;
    }
  }
  .BVRRSortAndSearch {
    float: #{$rdirection};
    height: auto;
    width: 25%;
    @include breakpoint($bp--slarge--down) {
      width: 20%;
    }
    @include breakpoint($bp--small-down) {
      width: 50%;
    }
    @include breakpoint($bp--xsmall-down) {
      float: #{$rdirection};
      height: auto;
      width: 100%;
    }
  }
}

.BVRRSortAndSearch {
  .BVFieldSearch {
    input {
      width: 100%;
    }
  }
}

.BVFieldSearch {
  float: none;
  margin: 0 auto;
  height: 60px;
  a.BVLink {
    &,
    &:visited {
      display: inline-block;
      zoom: 1;
      background-color: #ffffff;
      width: auto;
      padding: 20px;
      overflow: visible;
      vertical-align: top;
      cursor: pointer;
      color: #000000;
      font-family: $ano-bold_regular-font;
      font-size: 15px;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 18px;
      text-align: #{$ldirection};
      border-style: solid;
      border-color: #dbdbdb;
      border-width: 0 0 1px;
    }
    &,
    &:visited {
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      border-width: 0;
      padding: 0;
    }
  }
  span.BVLinkSpan {
    display: none;
  }
  input,
  input:focus {
    border-width: 0;
    background-color: #dbdbdb;
    color: #000000;
    width: 100%;
    padding: 0 15px;
    height: 60px;
    -webkit-appearance: none;
  }
  .BVFieldTextHelper {
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    color: #000000;
  }
  input {
    border-style: solid;
    border-color: #999999;
    vertical-align: top;
  }
}

.BVDIHidden {
  display: none;
}

ul.BVDI_QTFilterList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  zoom: 1;
}

ul.BVDI_tagdata,
ul.BVDI_QTFilterListCheckboxes {
  li.BVDI_QTFilter {
    label {
      padding-#{$ldirection}: 20px;
      background-repeat: no-repeat;
      text-decoration: none;
      color: #000000;
      background-image: url(https://maccosmetics.ugc.bazaarvoice.com/bvstaging/static/3820/iconFilters.png);
      background-position: right -17px;
      cursor: pointer;
    }
    &Hover {
      label {
        background-position: right -67px;
      }
    }
    &Checked {
      label {
        background-position: right -117px;
      }
    }
    &CheckedHover {
      label {
        background-position: right -166px;
      }
    }
  }
}

li.BVDI_QTFilter {
  overflow: hidden;
  padding-#{$rdirection}: 10px;
  margin-bottom: 8px;
  input {
    margin-#{$ldirection}: -40px;
    float: #{$ldirection};
  }
  .BVDIValue {
    padding-#{$rdirection}: 3px;
    padding-top: 2px;
    float: #{$ldirection};
  }
  .BVDINumber {
    color: #a3a3a3;
    font-size: 1rem;
    line-height: 1.5;
  }
  label {
    display: inline-block;
    zoom: 1;
  }
}

.BVDI_QT {
  height: auto;
  -moz-box-sizing: padding-box;
  z-index: 0;
  clear: both;
  zoom: 1;
  .BVDIToggleArea {
    width: 180px;
  }
}

.BVDI_QTAttributes {
  overflow: hidden;
  .BVDI_QTGroup {
    z-index: 2;
  }
  .BVDI_QTHeaderGroup {
    .BVDITitle,
    .BV_tag {
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      a.BVDILinkToggle {
        & {
          background: transparent none;
          border-width: 0;
          opacity: 1;
          filter: alpha(opacity=100);
          zoom: 1;
          filter: progid:DXImageTransform.Microsoft.Gradient(enabled=0);
          -ms-filter: progid:DXImageTransform.Microsoft.Gradient(enabled=0);
          @include swap_direction(padding, 0 0 0 15px);
          display: inline-block;
          white-space: nowrap;
          position: relative;
        }
        &,
        &:visited,
        &:hover,
        &:focus,
        &:active {
          color: #000000;
          font-family: $ano-bold_regular-font;
          font-size: 1.5rem;
          text-transform: uppercase;
          font-weight: normal;
          line-height: 3.2;
          height: 60px;
        }
        &:before {
          content: '';
          display: inline-block;
          transform: rotate(-135deg);
          border-style: solid;
          border-color: transparent;
          border-left-width: 8px;
          border-right-width: 8px;
          border-top-width: 0;
          border-bottom-width: 8px;
          border-bottom-color: black;
          margin-top: 6px;
          position: absolute;
          #{$rdirection}: 0;
          top: 22px;
        }
        .BVDILinkSpan {
          background-repeat: no-repeat;
          text-decoration: none;
          background-image: url(iconText.png);
          background-position: #{$rdirection} -10px;
          padding-#{$rdirection}: 4em;
          @include breakpoint($bp--slarge--down) {
            padding: 0 10px 0 2.5em;
          }
          @include breakpoint($bp--medium-down) {
            padding: 0 5px 0 2em;
          }
          @include breakpoint($bp--medium-down) {
            padding: 0 10px 0 25px;
          }
        }
        @include breakpoint($bp--slarge--down) {
          padding: 0 5px 0 0;
        }
      }
    }
  }
  a.BVDILink {
    &,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      line-height: 1.25;
      color: #000000;
      font-family: $ano-bold_regular-font;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: normal;
      border-width: 0;
      white-space: nowrap;
      outline: none;
    }
  }
  .BVDIToggleArea {
    border-style: solid;
    border-width: 1px;
    border-color: #c7c7c7;
    border-top-width: 0;
    background: #ffffff;
    -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    padding: 10px;
    z-index: 20000;
  }
  .BVDI_QTGroupCollapsed {
    .BV_tag {
      a.BVDILinkToggle {
        display: inline-block;
        position: relative;
      }
    }
  }
}

.BVDI_QTGroupDisabled {
  a.BVDILink {
    &,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: #a3a3a3;
      cursor: default;
    }
  }
}

.BVDI_QTHeaderAttributes {
  border-#{$rdirection}: 1px solid #c7c7c7;
  float: #{$ldirection};
  padding: 10px 1.5em 0px 1.5em;
  height: 60px;
  .BVDITitle {
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 3.5;
    height: 60px;
  }
}

.BVDI_QTBodyAttributes {
  height: 60px;
}

.BVDI_QTGroup {
  display: inline-block;
  .BVDINumber {
    font-style: normal;
    font-weight: normal;
    text-transform: none;
  }
}

.BVDI_QTDashboard.BVDI_QTDisplayStyle2 {
  border: 1px solid #c7c7c7;
  min-height: 128px;
  clear: both;
  zoom: 1;
  &:after {
    content: '.';
    height: 0;
    width: 0;
    visibility: hidden;
    display: block;
    clear: both;
  }
}

.BVDI_QTTitle {
  color: #000000;
  font-family: $ano-bold_regular-font;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: normal;
  margin-bottom: 10px;
  display: none;
}

.BVDI_QTSubtitle {
  color: #a3a3a3;
  font-size: 1rem;
  line-height: 1.5;
  display: none;
}

.BVRRDisplayContentLinkWrite {
  a {
    margin: 0 1%;
  }
  a,
  a:hover,
  a:focus,
  a:visited,
  a:active {
    @include breakpoint($bp--small-down) {
      display: block;
      float: none;
      text-align: center;
      white-space: nowrap;
    }
  }
}

.BVDIBody,
.BVVIVideoPopup {
  font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
  line-height: 1.25;
  color: #000000;
  font-size: 1rem;
}

.BVRRRatingSummary {
  .BVRROverallRatingContainer {
    .BVRRLabel {
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      padding-#{$rdirection}: 5px;
    }
    .BVRRRatingNormalLabel {
      font-style: normal;
      font-weight: normal;
      text-transform: none;
      float: #{$ldirection};
      line-height: 2.3;
    }
    .BVRRRatingNormalImage {
      img {
        width: 80%;
      }
    }
  }
  .BVRRLabel {
    font-style: normal;
    font-weight: normal;
    text-transform: none;
    padding-#{$rdirection}: 5px;
  }
  .BVRRRatingOverall {
    .BVRRRatingNormalOutOf {
      display: none;
    }
  }
  .BVRRRatingSummaryLinks {
    clear: both;
    .BVRRRatingSummaryLinkRead {
      a {
        float: right;
        clear: both;
      }
      .BVRRCount {
        float: right;
        clear: both;
      }
    }
    .BVRRRatingSummaryLinkWrite {
      padding: 1px 0 0 0;
    }
    .BVRRRatingSummaryLinkSeparator {
      float: right;
      padding: 0 8px;
    }
  }
}

.BVRRQuickTakeSummary {
  position: relative;
  .BVRRSecondaryRatingSummary {
    float: none;
    width: auto;
    padding: 0;
  }
  .BVRRPrimaryRatingSummary {
    width: auto;
    padding: 0;
    .BVRROverallRatingContainer {
      padding-top: 10px;
    }
    .BVRRRatingNormalLabel {
      text-transform: uppercase;
      padding-#{$rdirection}: 0;
      float: #{$ldirection};
      line-height: 3.3;
      @include breakpoint($bp--smedium--down) {
        float: none;
        line-height: 1.75;
      }
    }
    .BVRRRatingNormalOutOf {
      line-height: normal;
      clear: #{$ldirection};
    }
  }
}

.BVRRRatingNormalLabel {
  font-style: normal;
  font-weight: normal;
  text-transform: none;
}

.BVRRRatingNormalImage {
  float: #{$ldirection};
  padding-#{$rdirection}: 4px;
}

.BVImgOrSprite {
  overflow: hidden;
  display: inline-block;
}

.BVRRRatingNormalOutOf {
  color: #000000;
  font-family: $ano-bold_regular-font;
  font-size: 1.5rem;
  text-transform: uppercase;
  display: inline;
  float: #{$ldirection};
  line-height: 1.7;
}

.BVRRRatingEntry:after {
  content: '.';
  height: 0;
  width: 0;
  visibility: hidden;
  display: block;
  clear: both;
}

// Reviews Count

.BVRRCustomReviewCount {
  float: #{$ldirection};
  line-height: 3.2;
  padding: 10px 15px 0px 15px;
  height: 60px;
  width: auto;
  @include breakpoint($bp--xmedium--down) {
    width: auto;
    padding: 10px 5px 0;
  }
  @include breakpoint($bp--small-down) {
    padding: 10px 15px 0;
  }
  @include breakpoint($bp--xsmall-down) {
    border-#{$rdirection}: 0;
    width: 50%;
  }
}

//Reviews display content

.BVRRDisplayContent {
  clear: both;
  max-width: 1251px;
  margin: 0 auto;
  .BVRR_ReviewContainer {
    border-style: solid;
    border-color: #c7c7c7;
    border-width: 1px 0 0;
    width: 49%;
    margin: 25px auto 0;
    padding-top: 15px;
    zoom: 1;
    &:nth-child(odd) {
      float: #{$ldirection};
      @include breakpoint($bp--xsmall-down) {
        float: none;
        width: auto;
      }
    }
    &:nth-child(even) {
      float: #{$rdirection};
      @include breakpoint($bp--xsmall-down) {
        float: none;
        width: auto;
      }
    }
  }
}

.BVRRReviewDateContainer {
  color: #a3a3a3;
  font-size: 1rem;
  line-height: 1.5;
}

.BVRRReviewDatePrefix {
  display: none;
}

.BVRRReviewDate {
  font-style: normal;
  font-weight: normal;
  text-transform: none;
}

.BVRRReviewDisplayStyle5Header {
  overflow: hidden;
  zoom: 1;
  .BVRRReviewDateContainer {
    clear: none;
    float: #{$rdirection};
    line-height: 2;
  }
  .BVRRReviewRatingsContainer {
    margin-bottom: 15px;
    float: #{$ldirection};
    .BVRROverallRatingContainer {
      float: none;
    }
    .BVRRRatingNormalLabel {
      display: none;
    }
    .BVRRRatingNormalOutOf {
      color: #000000;
      font-family: $ano-bold_regular-font;
      font-size: 2.5rem;
      text-transform: uppercase;
      line-height: normal;
      display: none;
    }
  }
}

.BVRRReviewTitleContainer {
  clear: #{$ldirection};
  float: #{$ldirection};
  margin: 0 0 15px;
}

.BVRRReviewTitle {
  color: #000000;
  font-family: $ano-bold_regular-font;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: normal;
}

.BVRRReviewDisplayStyle5BodyContent {
  margin: 0 0 30px;
  .BVRRReviewTextContainer {
    .moreellipses {
      display: block;
    }
    a.more,
    a.less {
      display: inline-block;
      margin: 0.5em 0 0;
    }
    .BVRRReviewTextFull {
      display: inline-block;
    }
  }
}

.BVRRReviewDisplayStyle5BodyContentPrimary {
  vertical-align: top;
  margin-top: 15px;
}

.BVRRReviewDisplayStyle5Tags {
  margin-bottom: 15px;
  .BVRRLabel {
    display: block;
  }
}

.BVRRReviewText,
.BVRRReviewAbbreviatedText {
  line-height: 1.25;
  word-wrap: break-word;
  zoom: 1;
}

.BVRRReviewTextParagraph {
  margin-bottom: 10px;
}

.BVRRReviewDisplayStyle5Footer {
  margin-bottom: 15px;
  clear: both;
  .BVDI_FV {
    .BVDI_FVReportLinkInappropriate {
      float: #{$rdirection};
      @include breakpoint($bp--xmedium--down) {
        float: #{$ldirection};
      }
      @include breakpoint($bp--small-down) {
        display: none;
      }
    }
  }
}

.BVDI_FV {
  zoom: 1;
  .BVDI_FVVote {
    a {
      cursor: pointer;
    }
    a,
    span {
      text-decoration: none;
      color: #a3a3a3;
      font-size: 1rem;
      line-height: 1.5;
      float: #{$ldirection};
      white-space: nowrap;
    }
    .BVDINumber {
      font-weight: bold;
    }
  }
  .BVDI_FVVoting {
    a,
    a:visited,
    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      color: #a3a3a3;
      font-size: 1rem;
      line-height: 1.5;
      outline-width: 0;
    }
  }
  &:after {
    content: '.';
    height: 0;
    width: 0;
    visibility: hidden;
    display: block;
    clear: both;
  }
}

.BVRR_Feedback_Buttons {
  display: inline-block;
}

.BVDI_FVVoting {
  float: #{$ldirection};
  margin: 0;
  line-height: normal;
  zoom: 1;
  .BVDIPrefix {
    color: #a3a3a3;
    font-size: 1rem;
    line-height: 1.5;
    text-transform: uppercase;
    padding-#{$rdirection}: 3px;
    float: #{$ldirection};
  }
  &:after {
    content: '.';
    height: 0;
    width: 0;
    visibility: hidden;
    display: block;
    clear: both;
  }
}

.BVDI_FVVote {
  float: #{$ldirection};
  margin-#{$rdirection}: 1em;
}

.BVDI_FVNegative {
  margin-#{$ldirection}: -1px;
}

.BVDI_FVReportLink {
  a,
  a:hover,
  a:focus,
  a:visited {
    text-decoration: none;
    color: #a3a3a3;
    font-size: 1rem;
    line-height: 1.5;
  }
}

.BVRRReviewDisplayStyle5BodyUser {
  color: #a3a3a3;
  font-size: 1rem;
  line-height: 1.5;
  clear: both;
  overflow: hidden;
  zoom: 1;
  margin-bottom: 30px;
}

.BVRRUserNicknameContainer {
  clear: none;
  float: #{$ldirection};
  margin-#{$rdirection}: 10px;
}

.BVRRUserLocationContainer,
.BVRRContextDataValueContainer {
  text-transform: uppercase;
  display: block;
}

.BVRRUserLocationPrefix {
  margin-#{$rdirection}: 5px;
}

.BVRRContextDataValueProductPurchaseContainer {
  float: #{$ldirection};
  width: 100%;
}

.BVRRContextDataValue {
  padding-#{$rdirection}: 3px;
}

.BVContextDataContestSweepstakesNo {
  display: none;
}

.BVRRDisplayContentFooter {
  clear: both;
}

// Report inappropriate

.after-product {
  .product__reviews-spp {
    #BVSubmission_Container {
      margin: 0 auto;
    }
  }
}

#BVSubmission_Container {
  max-width: 1280px;
  margin: 2% auto;
  text-align: center;
}

.BVRRInappropriateReviewContentSubmission {
  max-width: 1280px;
  margin: 2% auto;
  padding: 0 1.2em;
  text-align: #{$ldirection};
}

.BVRRDisplayContentHeader {
  border-style: solid;
  border-color: #c7c7c7;
  border-width: 0 0 1px;
  border: none;
}

.BVRRTitle {
  color: #000000;
  font-family: $ano-bold_regular-font;
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: normal;
}

.BVRRDisplayContent {
  margin: 0 10px;
  clear: both;
  zoom: 1;
  @include breakpoint($bp--small-down) {
    margin: 0 15px;
  }
  .BVRRInappropriateReviewContentSubmission & {
    margin: 0;
  }
}

.BVRRDisplayContentReview {
  border-style: solid;
  border-color: #c7c7c7;
  border-width: 1px 0 0;
  width: 49%;
  margin: 25px auto 0;
  padding-top: 15px;
  zoom: 1;
  .BVRRInappropriateReviewContentSubmission & {
    border-top-width: 0;
    margin: 25px 0 0;
    @include breakpoint($bp--medium-down) {
      max-width: 100%;
      width: 100%;
    }
  }
}

.BVRRFieldContainerReviewInappropriateReasontext {
  max-width: 50%;
  @include breakpoint($bp--medium-down) {
    max-width: 100%;
    width: 100%;
  }
}

.BVRRInappropriateFeedbackProcessingDelay {
  margin: 15px 0;
}

.BVRRInappropriateFeedbackFormButtons {
  display: block;
  input[type] {
    border: 0;
    background: none;
  }
}

#BVRRCustomFeedbackFormButtonSubmit {
  display: inline-block;
  zoom: 1;
  background: #000;
  border-width: 0;
  padding: 20px;
  overflow: visible;
  vertical-align: top;
  cursor: pointer;
  color: #fff;
  font-family: $ano-bold_regular-font;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: 18px;
  width: 30%;
  float: #{$ldirection};
  clear: #{$ldirection};
  margin-bottom: 15px;
  margin-#{$rdirection}: 15px;
}

#BVRRCustomFeedbackFormButtonCancel {
  display: block;
  color: #000;
  font-family: $ano-bold_regular-font;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 18px;
  text-align: #{$ldirection};
  border-width: 0;
  background-color: transparent;
  padding: 0;
  width: auto !important;
  clear: #{$ldirection};
  margin-bottom: 15px;
  margin-#{$rdirection}: 15px;
}

#BVRRThankYouReturnLinkContainerID {
  margin: 20px 0;
  a {
    display: inline-block;
    zoom: 1;
    background: #000000;
    border-style: solid;
    border-width: 1px;
    border-color: #000000;
    padding: 20px;
    overflow: visible;
    vertical-align: top;
    cursor: pointer;
    color: #ffffff;
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    line-height: 18px;
    width: 30%;
  }
}

// Footer Load More Reviews
.BVRRPageBasedPager,
.BVRRPagerColumn {
  padding: 60px 0 3.5em;
  clear: both;
  overflow: hidden;
  zoom: 1;
  margin: 0 auto;
  text-align: center;
}

.BVRRPreviousPage,
.BVRRNextPage {
  a {
    border-style: solid;
    border-width: 1px;
    border-color: #000000;
    padding: 0 3em;
    line-height: 60px;
    height: 60px;
    display: inline-block;
    width: 100%;
    @include breakpoint($bp--xsmall-up) {
      width: 75%;
    }
    @include breakpoint($bp--small-up) {
      width: 50%;
    }
    @include breakpoint($bp--medium-up) {
      width: 30%;
    }
    @include breakpoint($bp--xlarge-up) {
      width: 20%;
    }
  }
}

/*
 ********** Write A Review *********
 */

#BV_SubmissionContainer {
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
  .picker {
    margin: 0;
    .picker-handle {
      display: none;
    }
    .picker-label {
      float: #{$ldirection};
    }
  }
}

.BV,
.BVSubmissionTimeout {
  font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
  line-height: 1.25;
  color: #000000;
  font-size: 1rem;
}

.BVPage {
  text-align: #{$ldirection};
  padding: 0 1.2em;
}

.BVPageBody {
  clear: both;
  &:after {
    content: '.';
    height: 0;
    width: 0;
    visibility: hidden;
    display: block;
    clear: both;
  }
}

.BVProductImageContainer {
  float: #{$ldirection};
  width: 50%;
  text-align: center;
  img {
    width: auto;
    height: auto;
    float: #{$ldirection};
  }
}

#BVSubmission_Container {
  #BVDivProductImageContainerID {
    .carousel-slide {
      max-width: 600px;
    }
  }
}

.BVProductNameContainer,
.BVStep1StyleDiv,
.writeareview_BVRR .BVFooter {
  float: #{$rdirection};
  width: 50%;
  clear: #{$rdirection};
  @include breakpoint($bp--small-down) {
    float: none;
    width: 100%;
  }
}

.BVProductNameContainer {
  margin-bottom: 15px;
  position: relative;
  zoom: 1;
  .BVSubjectName {
    color: #000000;
    font-family: $ano-bold_regular-font;
    font-size: 2.5rem;
    text-transform: uppercase;
    line-height: normal;
    display: inline-block;
    padding-bottom: 10px;
  }
}

.BVSpacer {
  clear: both;
}

.BVSectionHeader {
  border-style: solid;
  border-color: #dbdbdb;
  border-width: 1px 0 0;
  padding: 15px 0 0;
  margin-bottom: 15px;
  zoom: 1;
}

.BVMESectionHeader {
  color: #000000;
  font-family: $ano-bold_regular-font;
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: normal;
}

.BVModule {
  margin-bottom: 5px;
  clear: #{$ldirection};
  overflow: hidden;
  zoom: 1;
}

.BVFieldLabel {
  font-size: 1rem;
  line-height: 1.5;
  padding: 5px 0;
  display: none;
  .BVRadioField & {
    padding-bottom: 5px;
  }
  .BVFieldRecommend & {
    display: block;
    float: #{$ldirection};
    margin-#{$rdirection}: 1em;
    line-height: 2;
    @include breakpoint($bp--medium-down) {
      float: none;
      margin-#{$rdirection}: 0;
    }
  }
}

.BVImportantStarsField {
  clear: #{$ldirection};
  display: block;
  margin-bottom: 15px;
  label {
    display: inline;
    margin-bottom: 0;
  }
  .BVFieldLabel {
    display: block;
    @include swap_direction(padding, 0 0 30px);
    width: 48%;
    zoom: 1;
    @include breakpoint($bp--medium-down) {
      width: auto;
    }
  }
  .BVRatingStars {
    zoom: 1;
    overflow: hidden;
  }
}

.BVFieldLabelSuffix {
  display: none;
}

.BVRequiredFieldIndicator,
.BVRequiredFieldIndicatorLegend {
  font-weight: bold;
  color: #000000;
}

.BVFieldRecommend {
  label {
    display: inline;
  }
}

.BVRadioField {
  .BVFieldRadioContainer {
    display: block;
    float: #{$ldirection};
    margin-#{$rdirection}: 15px;
    @include swap_direction(padding, 5px 0 0);
    label {
      display: block;
      line-height: 2;
      background: url(https://maccosmetics.ugc.bazaarvoice.com/bvstaging/static/3820/iconFilters2.png) no-repeat right -198px;
      padding-#{$ldirection}: 2.5em;
      &:hover {
        background-position: right -247px;
        color: #68696d;
        cursor: pointer;
      }
      &.BVSelected {
        font-weight: normal;
        background-position: right -296px;
      }
      &.BVFieldError {
        background-position: 13px -344px;
      }
    }
  }
  label {
    display: block;
  }
}

.BVFieldProductPurchase,
.BVFieldContestSweepstakes {
  .BVFieldLabel {
    display: block;
  }
}

.BVFieldRadio {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
  @include swap_direction(margin, 0 2px 0 1px);
}

input.BVFieldRadio {
  width: 0;
  float: #{$ldirection};
  opacity: 0;
  @include swap_direction(margin, 0 0 0 -5px);
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.BVField {
  margin-bottom: 15px;
  clear: #{$ldirection};
  overflow: hidden;
  zoom: 1;
  .star.star_group_rating {
    float: right;
    width: 34px !important;
  }
  .BVRatingStarValueLabelContainer {
    padding: 0 10px 0 3px;
    .BVRatingStarsDisplayValue,
    .BVRatingStarsLegendValue {
      font-family: $ano-bold_regular-font;
      font-size: 1.5rem;
      text-transform: uppercase;
      color: #000000;
      line-height: 1.5;
    }
  }
}

.BVRRCustomBasicInformation {
  overflow: hidden;
  position: relative;
  zoom: 1;
  .BVTextField {
    float: #{$ldirection};
    clear: none;
    padding-#{$rdirection}: 10px;
    width: 50%;
    @include breakpoint($bp--medium-down) {
      width: auto;
      float: none;
      padding-#{$rdirection}: 0;
    }
  }
}

.BVFieldTextArea,
.BVFieldText,
.BVFieldSelect {
  border-style: solid;
  border-width: 1px;
  border-color: #999999;
  font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
  font-size: 1rem;
  color: #000000;
  width: 98%;
  padding: 21px 12px;
}

.BVMEFieldHelper {
  color: #a3a3a3;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: normal;
}

.BVFieldTextHelper {
  @extend .BVMEFieldHelper;
  .BVFieldRequired &,
  .BVImportantField & {
    @extend .BVMEFieldHelper;
  }
}

.BVSectionReviewSubmission {
  margin-bottom: 35px;
  .BVMEPopinTip {
    .BVHeader {
      display: block;
      padding-top: 15px;
    }
  }
}

.BVSection {
  padding-bottom: 15px;
  position: relative;
  zoom: 1;
}

.BVMEPopinTip {
  text-align: #{$ldirection};
  float: #{$ldirection};
  z-index: 1000;
  .BVMEPopinLink a {
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
  }
  .BVMEPopinContent {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #999999;
    box-shadow: 5px 8px 5px rgba(0, 0, 0, 0.1);
    color: #a3a3a3;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    width: 85%;
    padding: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    zoom: 1;
    z-index: 1000;
  }
  .BVMETipBoxClose {
    padding-left: 20px;
    background: url(https://maccosmetics.ugc.bazaarvoice.com/bvstaging/static/3820/iconText.png) no-repeat;
    padding: 0;
    display: block;
    height: 18px;
    text-align: left;
    text-indent: 2em;
    text-decoration: none;
    zoom: 1;
    overflow: hidden;
    background-position: left -260px;
    position: relative;
    margin-top: -15px;
    margin-right: -5px;
    width: 22px;
    float: right;
    cursor: pointer;
  }
}

.BVSectionBasicInformation {
  .BVCustomTagHeader {
    color: #000000;
    font-family: $ano-bold_regular-font;
    font-size: 2rem;
    font-weight: normal;
    text-transform: uppercase;
    line-height: normal;
    position: relative;
    zoom: 1;
  }
}

.BVTagsField {
  margin-bottom: 0;
}

.BVFieldPro,
.BVFieldCon {
  clear: none;
}

.BVMETagsHelper,
.BVTagSectionHeaderContainer {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  padding: 15px 0;
}

.BVTagsExpandedElement,
.BVTagsBlock {
  display: block;
}

.BVTagRow {
  float: #{$ldirection};
  margin-#{$rdirection}: 30px;
  padding: 3px 0 15px;
}

input.BVFieldCheckbox {
  width: 0;
  float: #{$ldirection};
  opacity: 0;
  margin: 5px 0 0 3px;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.BVCheckboxField,
.BVTag {
  label {
    display: block;
    background: url(https://maccosmetics.ugc.bazaarvoice.com/bvstaging/static/3820/iconFilters2.png) no-repeat right 0;
    padding-#{$ldirection}: 2.5em;
    line-height: 20px;
    &:hover {
      background-position: right -50px;
      color: #68696d;
      cursor: pointer;
    }
  }
}

.BVCheckboxField .BVFieldCheckbox:checked + label,
.BVSelectedTag label {
  background-position: right -100px;
  font-style: normal;
  &:hover {
    background-position: right -100px;
    cursor: pointer;
  }
}

.BVUserTagColumn {
  .BVTagRow {
    width: 44%;
    @include breakpoint($bp--medium-down) {
      width: 100%;
    }
  }
}

.BVUserTag input.BVTagTextBox {
  width: 100%;
}

.BVFooter {
  border-style: solid;
  border-color: #dbdbdb;
  border-width: 1px 0 0;
  padding-bottom: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  zoom: 1;
  clear: both;
  &:after {
    content: '.';
    height: 0;
    width: 0;
    visibility: hidden;
    display: block;
    clear: both;
  }
  @include breakpoint($bp--small-down) {
    padding: 10px;
  }
}
//.BVPageEdit
.writeareview_BVRR {
  .BVFieldAgreedtotermsandconditions {
    margin: 30px 0;
  }
  .BVModuleButtons {
    display: block;
    button.BVButton,
    button.BVButton:hover {
      width: 65%;
      margin-bottom: 15px;
      @include breakpoint($bp--medium-down) {
        display: block;
        width: 100%;
      }
      @include breakpoint($bp--small-down) {
        margin: 15px auto;
        text-align: center;
      }
    }
    @include breakpoint($bp--small-down) {
      display: block;
      float: none;
      margin-#{$ldirection}: 0;
    }
  }
}

.BVCheckboxField .BVFieldLabel {
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
  line-height: 25px;
  color: #000000;
  width: auto;
  float: none;
  padding-bottom: 0;
  display: inline;
}

.BVFieldAgreedtotermsandconditions {
  .BVFieldLabel {
    font-size: 1rem;
  }
  a {
    font-style: normal;
    font-weight: normal;
    text-transform: lowercase;
  }
}

.BVBody a,
a.BVLink {
  color: #000000;
  border-style: solid;
  border-color: #dbdbdb;
  border-width: 0 0 1px;
  text-decoration: none;
  &:visited {
    color: #000000;
    text-decoration: none;
  }
}

.BVFieldButton {
  clear: none;
  margin-bottom: 0;
  @include breakpoint($bp--small-down) {
    display: block;
    clear: both;
    margin-bottom: 10px;
  }
}

.BVFieldPreview,
.BVFieldSubmit {
  .BVButton {
    display: inline-block;
    zoom: 1;
    background: #000000;
    border-style: solid;
    border-width: 1px;
    border-color: #000000;
    padding: 20px;
    overflow: visible;
    vertical-align: top;
    cursor: pointer;
    color: #ffffff;
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    line-height: 18px;
    width: 30%;
  }
}

.BVFieldEdit,
.BVFieldCancel,
.BVFieldBack {
  .BVButton {
    clear: #{$ldirection};
    display: block;
    color: #000000;
    font-family: $ano-bold_regular-font;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 18px;
    text-align: #{$ldirection};
    border-style: solid;
    border-color: #dbdbdb;
    border-width: 0 0 1px;
    background-color: transparent;
    padding: 0;
    width: auto !important;
  }
}

.BVMERequiredFieldIndicatorSectionHeader {
  display: none;
}

#BVFieldUsernicknameFieldHelperID {
  display: none;
}

#BVDivCustomTagHeaderID {
  border-style: solid;
  border-color: #dbdbdb;
  border-width: 1px 0 0;
  padding: 15px 0 0;
  margin-bottom: 15px;
  zoom: 1;
}

/*
 *   BV Errors
 */

.BVErrors {
  margin-bottom: 20px;
  .BVHeader,
  .BVMESectionHeader {
    color: #f14a29;
    font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
    font-size: 12px;
  }
}

.BVErrorsBody {
  ul,
  li {
    color: #f14a29;
    list-style: disc;
  }
}

#BV_SubmissionContainer {
  .BVFieldError {
    color: #f14a29;
  }
}

.site-container {
  .product__reviews-spp {
    .BVPagePreviewReview {
      .BVMEPageHeader {
        color: $black;
        font-family: $ano-bold_regular-font;
        font-size: 2.5rem;
        text-transform: uppercase;
        line-height: normal;
        display: block;
      }
      .BVContentPreview {
        zoom: 1;
        margin: auto 0;
        padding-bottom: 30px;
        width: 80%;
      }
      .BVFooter {
        .BVModuleButtons {
          .BVButton {
            margin-top: 15px;
          }
        }
      }
    }
    .BVRR_Thankyou {
      .BVMEPageHeader {
        color: $black;
        font-family: $ano-bold_regular-font;
        font-size: 2.5rem;
        text-transform: uppercase;
        line-height: normal;
        display: block;
      }
      .BVFooter {
        padding-top: 15px;
        .BVLinkReturnToContent {
          display: inline-block;
          zoom: 1;
          background: $black;
          border: 1px solid $black;
          padding: 20px;
          overflow: visible;
          vertical-align: top;
          font-family: $ano-bold_regular-font;
          text-transform: uppercase;
          text-decoration: none;
          text-align: center;
          line-height: 18px;
          width: 30%;
          color: $white;
        }
      }
    }
  }
}
