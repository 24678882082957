.enhanced-delivery-page {
  &.checkout {
    &.adpl_enabled {
      .adpl {
        textarea.field {
          resize: none;
          & + label {
            display: block;
            &::before {
              background: white;
              content: attr(data-required) attr(placeholder);
              display: inline-block;
              margin: 0 28px;
              transform-origin: $ldirection center;
              transition-delay: 0;
              transition-duration: 0.2s;
              transition-timing-function: ease-out;
            }
          }
        }
        textarea + label {
          span.label-content {
            display: none;
          }
        }
      }
    }
    .checkout-progress-bar {
      margin: 32px 0 0;
      &__list {
        counter-reset: step;
        margin: 0;
        overflow: hidden;
        padding: 0;
        width: 100%;
      }
      &__list-item {
        color: $color--gray--white;
        float: $ldirection;
        font-family: $ano-bold_regular-font;
        font-size: 12px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 33.33%;
        a {
          border-bottom: 0;
          color: $color--gray--white;
          text-decoration: none;
          &:hover,
          &:visited {
            color: $color--gray--white;
            text-decoration: none;
          }
        }
        &::before {
          border-radius: 50%;
          content: counter(step);
          counter-increment: step;
          display: block;
          margin: 0 auto 5px;
          position: relative;
          z-index: 2;
        }
        &::after {
          #{$ldirection}: -50%;
          content: '';
          position: absolute;
          width: 100%;
          z-index: 1;
        }
        &:first-child::after {
          display: none;
        }
        &--complete {
          color: $color--black;
          font-weight: bold;
          a {
            color: $color--black;
            &:hover,
            &:visited {
              color: $color--black;
            }
          }
        }
        &--payment,
        &--review {
          &.checkout-progress-bar__list-item--complete {
            &::after {
              background: $color--black;
            }
          }
        }
      }
    }
    .accordionPanel {
      i {
        &::before {
          #{$ldirection}: -2px;
        }
      }
    }
    .column {
      &.checkout__sidebar {
        &.right {
          .back_to_top {
            .sticky-back-to-top {
              top: 40%;
              transform: rotate(-45deg);
            }
          }
          .billing-address {
            &__content {
              a {
                #{$ldirection}: calc(100% - 40px);
                background-size: 18px;
                height: 18px;
                width: 18px;
              }
            }
          }
          #shopping-bag-wrapper-panel {
            .panel {
              &.shopping-bag-panel {
                .item-count__edit {
                  .shopping-bag-edit-icon {
                    background-size: 18px;
                  }
                }
              }
            }
          }
          .shipping-address-content-panel-edit {
            background-size: 18px;
            height: 18px;
            width: 18px;
          }
        }
      }
    }
    .panel#shipping-panel {
      .field-container--grid {
        .select2-container {
          width: 100%;
          .select2-choice {
            .select2-arrow {
              b {
                &::before {
                  #{$rdirection}: 0;
                  bottom: 12px;
                  opacity: 0.5;
                  transform: rotate(135deg);
                  @include breakpoint($width-largest) {
                    transform: rotate(-90deg);
                  }
                }
              }
            }
          }
        }
      }
      .info-container.info-link-content {
        .info-link {
          margin-#{$ldirection}: 0;
        }
      }
    }
    .gift-options {
      .giftwrap {
        .picker-checkbox {
          .picker-flag {
            &::before {
              #{$ldirection}: 18px !important;
            }
          }
        }
      }
    }
    .giftmessage {
      .form-item.card-message {
        .gift_option_giftmessage {
          .message-field {
            height: 135px;
            &.touched + label,
            &:focus + label {
              &::before {
                @include transform(translateY(-5px) scale(0.8, 0.8));
                font-size: 14px;
                top: 0;
              }
            }
          }
          label {
            color: $color--gray--lighter;
            display: block;
            &::before {
              content: attr(data-required) attr(placeholder);
              font-size: 14px;
              line-height: 1;
              margin: 0 16px;
              position: absolute;
              text-transform: uppercase;
              top: 15px;
              transition: all 0.2s ease-out;
            }
          }
        }
      }
    }
  }
  .checkout {
    #shipping-panel {
      .billing-address-form-fields {
        .country-id {
          label {
            display: none;
          }
        }
        .field-container {
          .country-id.select {
            &::after {
              #{$ldirection}: auto;
            }
          }
        }
      }
    }
  }
}
