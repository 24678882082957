.i18n-ar-e-SA {
  .store-locator {
    .select2-container {
      .select2-choice {
        .select2-arrow {
          b {
            transform: rotate(90deg);
          }
        }
      }
    }
    select {
      background-position: 5% 54%;
    }
    .doors_results {
      .door_row {
        td {
          text-align: #{$ldirection};
        }
      }
    }
  }
}

.site-container {
  #contact_us {
    .field-container {
      .additional_question {
        width: 70%;
        @include swap_direction(border, 1px solid $color--gray--light);
        @include swap_direction(margin, 15px auto 20px);
        @include swap_direction(padding, 7px 0px);
        text-align: center;
        @include breakpoint($bp--xsmall-down) {
          width: 100%;
        }
      }
    }
  }
  .artistry-video-player-wrapper {
    .prod_inv_status-2 {
      .product__footer {
        .temp-out-of-stock {
          .temp-outof-stock_text {
            position: relative;
            top: 10px;
          }
        }
      }
    }
  }
  .store-locator {
    .form--search--advanced {
      .options {
        .form-item-wrapper {
          @include breakpoint($bp--medium-down) {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.page--spp__custom_palette {
  .product__name-editable.editing {
    input.product__name-field {
      width: auto;
    }
    .close-link {
      float: #{$ldirection};
    }
  }
}

[dir='rtl'] {
  select {
    background-position: 5% 54%;
    background-image: image-url('fallbacks/corner-triangle-reversed.png');
  }
}

.site-container {
  .page--spp__product {
    .product__footer {
      ul.product__inventory-status {
        a.notify_me {
          @include swap_direction(margin, 20px 20px);
          width: 85%;
          position: static !important;
        }
        .temp-out-of-stock__text,
        li.coming-soon,
        li.inactive {
          @include swap_direction(margin, 0 15px 0 0);
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .field {
    .email_input {
      width: 70%;
      input {
        width: 100%;
      }
    }
  }
}

.site-container {
  .product__footer {
    ul.product__inventory-status {
      .product__inv-status-item {
        float: none !important;
        a.notify_me {
          position: relative !important;
          float: #{$rdirection};
        }
      }
    }
  }
}

[dir='rtl'] {
  .site-container {
    .product__footer {
      .temp-outof-stock_text {
        position: absolute;
        #{$ldirection}: 25px;
        @include swap_direction(padding, 1em 0 0 0);
      }
    }
  }
  .artistry-video-player__products {
    .product--teaser {
      .product__footer {
        .product__price {
          text-align: #{$rdirection};
        }
      }
    }
  }
  .site-navigation {
    .field-menu {
      ul.menu {
        li {
          @include swap_direction(margin, 0);
        }
      }
    }
  }
  .media-block--video {
    .play.icon--caret-right {
      padding-bottom: 10px;
      bottom: 180px;
    }
  }
  .spp-product-layout__content {
    .block-headline,
    .block-eyebrow {
      font-family: $ano-bold_regular-font;
      letter-spacing: normal;
    }
  }
}

.contact-us-thanks-page {
  @include swap_direction(padding, 0 1em);
}

#google_map_locate_address {
  width: auto !important;
  @include swap_direction(border, 2px solid);
  height: 300px;
  #floating-panel {
    width: auto !important;
    input#address-latlng {
      width: 50%;
    }
  }
  #google_pick_map {
    width: auto !important;
    height: 274px;
  }
}

.password-request-page,
.password-sent-page {
  @include swap_direction(padding, 20px 20px 0 10px);
}

.grid--mpp__item {
  .prod_inv_status-5 .inactive {
    display: block !important;
  }
}

.add-payment-page {
  @include swap_direction(padding, 0 10px 10px 10px);
  .form_element {
    @include swap_direction(margin, 0 0 1em 0);
    &.expiration_date_container {
      select:first-child {
        @include swap_direction(margin, 0 0 1em 0);
      }
    }
  }
}

.page--spp__custom_palette.is-mobile {
  .product__footer {
    .product__add-to-bag {
      position: relative;
    }
    .product__add-to-bag.btn--disabled {
      display: none;
    }
  }
}

.address-overlay-billing {
  .form_element {
    @include swap_direction(margin, 15px 0 0 0);
  }
  .address-form__submit {
    @include swap_direction(margin, 5px 0 0 0);
  }
}

.artist-video-carousel-v1.artist--videos {
  .carousel {
    .media-block--video {
      .media-block__caption--two-col > footer {
        float: #{$rdirection};
      }
    }
  }
}

.section-viva-glam {
  .multi-use-tout {
    display: block;
  }
  .content-4col-v1.content-4col__grid {
    margin: 0px !important;
    padding-bottom: 1em;
  }
  .content-4col__column--2 {
    width: 100% !important;
    .content-4col__content {
      padding-#{$rdirection}: 0em;
    }
  }
}

#BVSubmissionContainer {
  .BVSectionBasicInformation {
    .BVTagColumn {
      .BVTagRow {
        width: 50%;
        margin-#{$rdirection}: 0px;
      }
    }
  }
}

ul.select2-results {
  max-height: 250px;
}

.responsive-container.password-reset-page {
  padding: 0px 10px 0 10px;
}

.section-artistry-all-artists-videos {
  .artistry-videos__formatter {
    margin-bottom: 0px;
  }
  .artistry-video-section__grid {
    .grid--item:last-child {
      border-bottom: none;
    }
  }
}

.i18n-ar-e-SA {
  .product__mini-detail {
    span {
      width: 60%;
      float: #{$rdirection};
    }
    a {
      width: 10%;
    }
  }
}

// Hide the shipping tout on the mobile SPPs
// The original implementation only enabled them in the PC css
.product__shipping {
  display: none;
}

.i18n-ar-e-SA {
  .collection-products__list {
    .product__price--non-sale {
      float: left;
      padding-right: 8px;
    }
  }
  .grid--mpp.responsive-container {
    .grid--mpp__item {
      .product__price {
        .product__price--non-sale {
          float: left;
          padding-right: 8px;
        }
      }
    }
  }
  article.page--spp__product {
    .product__price .product__price--sale {
      float: right;
      padding-left: 8px;
    }
  }
}

.i18n-ar-e-SA {
  .block-template-tabbed-block-v1 {
    .slick-slider {
      direction: ltr !important;
    }
  }
  .homepage-formatter__rows {
    .tabbed-products-block__content {
      .product-brief__footer {
        .product-price--sale {
          float: #{$ldirection};
        }
      }
    }
  }
}

.product_content_fav_mobile {
  .product__sticky-footer {
    display: none;
  }
}
