.tabbed-products-block {
  &__tab {
    min-height: 60px;
  }
}

.product-foundation-vto {
  &__btn {
    @include swap_direction(padding, 6px 3px 24px 38px);
    width: 200px;
  }
}

[dir='rtl'] {
  .product-foundation-vto {
    &__btn {
      @include swap_direction(padding, 6px 38px 24px 3px);
      width: 212px;
    }
  }
}

.site-container {
  .field-mobile-template {
    .trending-looks-block-item-overlay-stage {
      z-index: 999999;
      background: transparent;
      &__content {
        background-color: $color--white;
      }
    }
  }
}
