.checkout.panel {
  #shipmethod-panel {
    .ship-method-group-label {
      .picker {
        .picker-label {
          width: 83%;
        }
      }
    }
  }
}

#review-panel {
  .address.two-column-form {
    .view-address {
      .btn.change-address {
        border-bottom: 1px solid $color--gray--lightest;
        min-width: 0;
        @include swap_direction(padding, 0 5px 0 0);
        height: 10px;
        line-height: 1.5px;
        background: none;
        color: $color--black;
        font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif;
        font-size: 12px;
        width: auto;
      }
    }
  }
}

#checkout_billing_panel {
  .related-media span {
    @include swap_direction(padding, 0 5px);
  }
}

[dir='rtl'] {
  @mixin margin-padding($pixel) {
    margin-#{$ldirection}: $pixel;
    padding-#{$ldirection}: $pixel;
  }
  .checkout {
    .product__thumb {
      @include margin-padding(0px);
    }
    .order-summary__content {
      .label {
        @include margin-padding(0px);
        margin-#{$ldirection}: 0px;
      }
    }
    .checkout__content {
      .cart-item {
        .cart-item__qty {
          padding-#{$rdirection}: 0;
        }
      }
    }
  }
  #site-header {
    .mobile-checkout-back {
      position: relative;
      right: 20px;
      text-align: #{$ldirection};
      transform: rotate(180deg);
    }
  }
  .checkoutdotcom-container {
    .date-container,
    .code-container {
      @include breakpoint($bp--small-up) {
        margin-left: 1em;
        margin-right: 0;
      }
    }
  }
}

.picker.picker-checkbox,
.picker.picker-radio {
  .picker-handle {
    @include swap_direction(margin, 0 5px 0 2px);
    float: $ldirection;
  }
  .picker-label {
    float: $ldirection;
  }
}

.site-container {
  .confirm.viewcart {
    .checkout__content {
      @include swap_direction(padding, 0 1em);
      #registration-panel {
        margin-#{$ldirection}: 0% !important;
      }
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .product-header {
          h6.rating {
            width: auto;
          }
        }
      }
    }
  }
}

#new-or-returning-radios {
  fieldset {
    float: $ldirection;
  }
}

.checkout__content {
  .select2-container.error {
    @include swap_direction(border, 1px solid red);
  }
}

.column.right.checkout__sidebar {
  #viewcart-shopping-bag-panel {
    .viewcart-panel__content {
      .cart-item__qty {
        @include swap_direction(padding, 0em);
        width: 33%;
      }
      .cart-item__total {
        text-align: $ldirection;
        margin-top: 1.5em;
      }
    }
  }
}

.form-item.checkbox.error {
  label {
    color: red;
    a {
      color: red;
    }
  }
  .picker-checkbox {
    .picker-handle {
      border-color: red;
    }
  }
}

.checkout {
  .cart-item__total {
    width: auto !important;
  }
}

[dir='rtl'] {
  .site-container {
    .checkout__sidebar {
      .viewcart-header {
        padding: 2em !important;
        h2 {
          float: $ldirection;
          padding: 0 0 0 0;
          margin: -10px;
        }
        #header-item-count {
          float: $rdirection;
          margin: -10px;
        }
      }
    }
  }
}

.product--shaded--single {
  .product__sku-details {
    .view-all-shades--mobile,
    .product__product-details-shade--single {
      display: none;
    }
  }
}

#checkout_shipping_panel {
  .form-item.latitude,
  .form-item.longitude,
  .form-item.google_map_info {
    display: none;
  }
}

.card-expiry-date {
  .nojs_hidden {
    select {
      width: 45%;
    }
  }
}

bdo {
  &.phone-direction {
    direction: ltr;
  }
}

.checkoutdotcom-container {
  .checkoutdotcom-field {
    @include swap_direction(border, 1px solid $color--gray--lightest);
    @include swap_direction(border-radius, 0);
    @include swap_direction(margin, 1em 1em 1em 0);
    height: 60px;
    padding: 0 12px;
    &.error {
      border-color: $color--red;
      box-shadow: none;
    }
  }
  .card-number-frame {
    @include breakpoint($bp--large-up) {
      width: 65%;
    }
  }
  .date-container,
  .code-container {
    @include breakpoint($bp--small-up) {
      width: 40%;
      float: $ldirection;
      margin-right: 1em;
    }
    @include breakpoint($bp--large-up) {
      width: 11em;
    }
  }
}

.sticky-checkout-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1002;
  padding: 15px;
  background: $color--white;
  border-top: 1px solid $color--black;
  .btn {
    width: 100%;
  }
}
