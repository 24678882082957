.enhanced-delivery-page {
  &.checkout {
    &.active-panel-review {
      #checkout-review-panel {
        padding-#{$ldirection}: 16px;
      }
      .review-panel {
        &.panel {
          .single-message {
            &.error_invalid_offer_code {
              display: none !important;
            }
          }
          .checkoutdotcom-container,
          .policy-container {
            padding-#{$ldirection}: 16px;
          }
          .field-container {
            &.payment-type {
              border-bottom: 1px solid $color--mercury;
              .picker-radio {
                .related-media {
                  float: $rdirection;
                  margin-top: -2px;
                  span {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .checkout__title {
            font-size: 36px;
            margin-bottom: 16px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
    .panel#shipping-panel {
      .field-container--grid {
        .select2-container.field {
          width: 100%;
          .select2-arrow {
            b::before {
              opacity: 1;
            }
          }
        }
        .select2-container,
        select.field {
          & + .valid_marker {
            display: none;
          }
        }
      }
      .edit-address-container {
        .country-id {
          margin-bottom: 2px;
        }
        .department_name_container {
          display: none;
        }
        .default_ship_container.radio {
          .input_default.picker-element {
            display: none;
          }
        }
      }
      .delivery-address-container {
        .picker-checkbox {
          width: 100%;
          .picker-handle {
            @include swap_direction(margin, 0 13px 0 0);
            .picker-flag {
              &::before {
                position: absolute;
              }
            }
          }
        }
      }
      .new-address-container {
        .default-shipping,
        .bill-to-shipping .picker-checkbox {
          margin: 22px 0 0;
          .picker-handle {
            @include swap_direction(margin, 0 13px 0 0);
          }
        }
      }
      .phone_2_prefix_container,
      .department_name_container {
        label,
        .valid_marker {
          display: none;
        }
      }
      .address6 {
        display: none;
      }
      .field-container {
        .form-item {
          &.select,
          &.phone_2_prefix_container {
            .select2-container {
              width: 100%;
            }
          }
        }
        textarea.field {
          &.adaptive-label-mode,
          &:focus {
            & + label::before {
              width: 100%;
            }
          }
        }
      }
      .billing-address-form-fields {
        .postal_code {
          margin-bottom: 22px;
        }
        .field-container {
          .country-id.select,
          .phone-prefix {
            .valid_marker,
            .invalid_marker,
            label {
              display: none;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .checkout-shipping-panel-section {
        &.address-list-added {
          & ~ .billing-address-form-fields:not(.hidden) {
            border-bottom: 1px solid;
          }
        }
        .form-item {
          &.city,
          &.state,
          &.phone-prefix {
            &::after {
              #{$rdirection}: 25px;
              border: 5px solid transparent;
              border-top-color: $color--black;
              content: '';
              pointer-events: none;
              position: absolute;
              top: 26px;
              @include breakpoint($width-xlarge) {
                border: 0;
              }
            }
            .valid_marker,
            .invalid_marker,
            label {
              display: none;
            }
            select {
              text-decoration: none;
            }
          }
        }
      }
    }
    .column {
      &.checkout__sidebar {
        #offer-code-panel {
          #offer_code {
            .valid_marker {
              top: 15px;
            }
          }
        }
        #continue-btn {
          padding: 8px 6px;
          @include breakpoint($width-xlarge) {
            padding: 0 16px;
          }
          .submit {
            .btn {
              line-height: 1.4;
              margin: 0;
              visibility: visible;
            }
          }
        }
      }
    }
    .info-link-content {
      .info-link {
        margin-#{$ldirection}: 0;
      }
    }
    .checkout-shipping-panel-section {
      & + .select-menu {
        border: 0;
      }
      .select-address {
        .select-menu {
          &:not(.shipping-address-list) {
            border: 0;
          }
        }
      }
      &.address-list-added {
        & ~ .form-item.address-to-use {
          .radio:last-child {
            .picker-radio {
              border-bottom: 1px solid;
              &.checked {
                border-bottom: 0;
              }
            }
          }
        }
        & ~ .info-link-content {
          .info-link {
            margin-#{$ldirection}: 13px;
          }
        }
      }
    }
    .gift-options {
      .giftmessage {
        .card-message {
          p {
            display: none;
          }
          label {
            &::before {
              font-size: 12px;
            }
          }
          .message-field {
            &.touched + label {
              &::before {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
